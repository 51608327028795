import React from 'react';
import {config} from '../config/config';

export function BackgroundContainer(props) {
  const backgroundUrl = config.get('backgroundUrl');

  const style = {};

  if (backgroundUrl) {
    style.backgroundImage = `url(${backgroundUrl})`;
  }

  // Styles can be: legacy, branding2025
  const backgroundStyle = config.get('backgroundStyle');

  if (backgroundStyle === 'branding2025') {
    style.backgroundPosition = 'center';
  }

  return (
    <>
      <div className="bg-container" style={style}>
        <div className="box-bg-color h-100"></div>
        {props.children}
      </div>
    </>
  );
}
