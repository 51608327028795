import React from 'react';
import {FormattedMessage} from 'react-intl';
import {config} from '../config/config';
const cdn = config.get('baseUrls').cdn;
const whitelabel = !!config.get('webfeatures').whitelabel;

const PoweredByNormal = () => {
  return (
    <span className="float-right learnifier-logo">
      {/* Safe link to the own website */
      /* eslint-disable-next-line react/jsx-no-target-blank */}
      <a
        href="https://www.learnifier.com?utm_campaign=learnifier&amp;utm_medium=footerlogo&amp;utm_source=loginsite"
        target="_blank"
        rel="noopener"
      >
        <span className="transparent">
          <FormattedMessage id="loginsite.footer.powered" />
        </span>
        <img
          src={`${cdn}cocobox/img/learnifier-logos/logo-white-183x70px.png`}
          width={183}
          height={70}
          alt="Powered by Learnifier"
        />
      </a>
    </span>
  );
};

const PoweredByWhitelabel = () => {
  return (
    <span className="float-right learnifier-logo">
      {/* Safe link to the own website */
      /* eslint-disable-next-line react/jsx-no-target-blank */}
      <a
        href="https://www.learnifier.com?utm_campaign=learnifier&amp;utm_medium=footerlogo&amp;utm_source=loginsite"
        target="_blank"
        rel="noopener"
      >
        <span className="transparent">
          <FormattedMessage id="loginsite.footer.powered.whitelabel" />
        </span>
      </a>
    </span>
  );
};

const PoweredBy = () => {
  return whitelabel ? <PoweredByWhitelabel /> : <PoweredByNormal />;
};

export default PoweredBy;
